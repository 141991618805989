import { PageProps } from 'gatsby';
import { BannerData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import params from '~/params/banner.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageBannersUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { bannerId, espaceId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <Form
        {...props}
        docId={bannerId}
        model={new BannerData({ espaceId, params })}
        type="update"
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageBannersUpdate);
